import React from 'react';
import './cancelButton.scss';

interface ICancelButton {
  title: string;
  confirmAction?: Function;
}

const CancelButton = (props: ICancelButton) => {
  return (
    <button onClick={() => props.confirmAction()} className="cancel-button">
      {props.title}
    </button>
  );
};

export default CancelButton;
