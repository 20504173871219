import React, { useCallback, useEffect, useRef, useState } from 'react';
import UploadComponents from 'app/entities/product/UploadComponents';
import UploadFile from 'app/entities/product/UploadFile';
import RoomleConfiguratorApi from '@roomle/embedding-lib/roomle-configurator-api.es.min.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getEnabledManufacturers } from 'app/entities/manufacturer/manufacturer.reducer';
import { IRootState } from 'app/shared/reducers';
import { AvFeedback, AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Collapse, Label, Row } from 'reactstrap';
import { createEntity, getEntity, reset, updateEntity } from './product.reducer';
import { FOOD_SERVICE_EQUIPMENT, SPECIALITY_EQUIPMENT } from 'app/config/constants';
import './product.scss';

export interface IProductUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

const initialProgress = { 2017: 0, 2018: 0, 2019: 0, 2020: 0, 2021: 0, 2022: 0 };
export const ProductUpdate = (props: IProductUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);
  const [savingEntity, setSavingEntity] = useState(false);
  const [configurationId, setConfigurationId] = useState(null);
  const [filename, setFilename] = useState('');
  const [roomleConfiguration, setRoomleConfiguration] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isButtonEnabled, setIsButtonEnable] = useState(false);
  const [files, setFiles] = useState({});
  const [update, setUpdate] = useState(true);
  const [progress, setProgress] = useState(initialProgress);

  const ref2017 = useRef(null);
  const ref2018 = useRef(null);
  const ref2019 = useRef(null);
  const ref2020 = useRef(null);
  const ref2021 = useRef(null);
  const ref2022 = useRef(null);

  const { productEntity, manufacturers, loading, updating } = props;

  const handleClose = () => {
    props.reset();
    props.history.push('/product' + props.location.search);
  };

  useEffect(() => {
    if (configurationId == null || props.loading) return;

    (async () => {
      const options = {
        locale: 'en',
        moc: false,
        translations: {
          en: {
            params: {
              'request-product': 'Save Configuration',
            },
          },
        },
      };

      const roomleConfigToLoad = configurationId.split(':');

      const configurator = await RoomleConfiguratorApi.create(roomleConfigToLoad[0], document.getElementById('configurator-container'), {
        ...options,
        buttons: {
          savedraft: false,
          startconfigure: false,
          requestproduct: false,
          export3d: false,
        },
      });

      await configurator.ui.loadObject(configurationId);

      configurator.ui.callbacks.onRequestProduct = (confId, image, partlist, price, labels, configuration) => {
        setRoomleConfiguration(configuration);
        setIsButtonEnable(true);
      };
      configurator.ui.triggerRequestProduct();
    })();
  }, [configurationId, props.loading]);

  useEffect(() => {
    if (props.productEntity != null && Object.keys(props.productEntity).length) {
      setConfigurationId(props.productEntity.roomleComponentId);
    }
  }, [props.productEntity]);

  useEffect(() => {
    setConfigurationId(null);
    setRoomleConfiguration(null);
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }

    props.getEnabledManufacturers();
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      // handleClose();
      setSavingEntity(false);
    }
  }, [props.updateSuccess]);

  const getParameterMapping = useCallback(roomleConfig => {
    const parsedJson = JSON.parse(roomleConfig.configuration);
    if (parsedJson && parsedJson.parameters && parsedJson.parameters.parameterlabel) {
      const parametersMappingHeaders = parsedJson.parameters.parameterlabel.split(',');
      const parametersMappingValues = parsedJson.parameters.parameterlabelvalue.split(',');

      const parametersMapping = {};

      const length = parametersMappingHeaders.length;
      for (let i = 0; i < length; i++) {
        const header =
          parametersMappingHeaders[i] === '' || parametersMappingHeaders[i] === null ? 'Model Number' : parametersMappingHeaders[i];

        if (parametersMappingValues[i] !== null && parametersMappingValues[i] === 'null') {
          continue;
        }
        parametersMapping[header] = parametersMappingValues[i];
      }

      return parametersMapping;
    } else {
      return '';
    }
  }, []);

  const getHashConfiguration = roomleConfig => {
    return roomleConfig.configurationHash;
  };

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const parsedJson = JSON.parse(roomleConfiguration.configuration);
      let parameters = '';
      if (parsedJson && parsedJson.parameters && parsedJson.parameters.parameterlabel) {
        const parametersMappingHeaders = parsedJson.parameters.parameterlabel.split(',');
        const parametersMappingValues = parsedJson.parameters.parameterlabelvalue.split(',');
        parameters = parametersMappingHeaders + '\\n' + parametersMappingValues;
      }
      const typecatalog = parsedJson.parameters ? parsedJson.parameters.typecatalog : '';
      const typecatalogheader = parsedJson.parameters ? parsedJson.parameters.typecatalogheader : '';
      const entity = {
        ...productEntity,
        ...values,
        parameters,
        typecatalog,
        typecatalogheader,
      };

      setSavingEntity(true);
      if (isNew) {
        props.createEntity(entity, files);
      } else {
        props.updateEntity(entity, files);
      }

      // setConfigurationId(null);
      // props.reset();
    }
  };

  const onRoomleComponentIdChange = field => {
    setConfigurationId(null);
    const value = field.target.value;
    setTimeout(function () {
      setConfigurationId(value);
    }, 500);
  };

  // const onBlobChange = year => event => {
  //    // files[year] = event.target.files[0];
  //    // setFilename(event.target.files[0].name);
  //    // setFiles(files);
  //    setUpdate(!update);

  //    let file = event.target.files[0];
  //    let yearValue = year;
  //    let fileName = event.target.files[0].name;

  //    axios.get(`api/products/generateFileUploadSignedLink`, {
  //      params: {
  //        productId: productEntity.id,
  //        year,
  //        fileName
  //      }
  //    }).then(link => {
  //      const options = {
  //        headers: {'Content-Type': "application/octet-stream"},
  //        onUploadProgress: function (progressEvent) {
  //          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
  //          setProgress({...progress, [yearValue]: percentCompleted})
  //        }
  //      };
  //      const instance = axios.create();
  //      delete instance.defaults.headers.common['Authorization'];
  //      // @ts-ignore
  //      instance.put(link.data, file, options).then(() => {
  //        axios.post(`api/products//${productEntity.id}/refresh-available-file-versions`);
  //      })
  //    })
  //  };

  const clearBlob = name => event => {
    files[name] = null;
    if (ref2017 !== null && name === '2017') {
      ref2017.current.value = null;
    }
    if (ref2018 !== null && name === '2018') {
      ref2018.current.value = null;
    }
    if (ref2019 !== null && name === '2019') {
      ref2019.current.value = null;
    }
    if (ref2020 !== null && name === '2020') {
      ref2020.current.value = null;
    }
    if (ref2021 !== null && name === '2021') {
      ref2021.current.value = null;
    }
    if (ref2022 !== null && name === '2022') {
      ref2022.current.value = null;
    }
    setFiles(files);
    setUpdate(!update);
  };

  const toggle = () => setIsOpen(!isOpen);

  const file2017 = files[2017] != null ? files[2017] : null;
  const file2018 = files[2018] != null ? files[2018] : null;
  const file2019 = files[2019] != null ? files[2019] : null;
  const file2020 = files[2020] != null ? files[2020] : null;
  const file2021 = files[2021] != null ? files[2021] : null;
  const file2022 = files[2022] != null ? files[2022] : null;
  const contentType = productEntity != null ? productEntity.fileContentType : '';

  return (
    <div>
      <div>
        <h2 id="familyBuilderApiApp.product.home.createOrEditLabel" className="create-product-page-title">
          Create or edit a Product
        </h2>
      </div>
      <div>
        {savingEntity ? (
          <p>Saving...</p>
        ) : (
          <>
            {loading ? (
              <p>Loading...</p>
            ) : (
              <>
                <AvForm model={isNew ? {} : productEntity} onSubmit={saveEntity} style={{ paddingBottom: 30 }}>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <div style={{ width: '50%' }}>
                      {!isNew ? (
                        <AvGroup>
                          <Label for="product-id" className="create-product-page-label">
                            ID
                          </Label>
                          <AvInput id="product-id" type="text" className="form-control" name="id" required readOnly />
                        </AvGroup>
                      ) : null}
                      <AvGroup>
                        <Label id="nameLabel" for="product-name" className="create-product-page-label">
                          Name
                        </Label>
                        <AvField
                          id="product-name"
                          type="text"
                          name="name"
                          validate={{
                            required: { value: true, errorMessage: 'This field is required.' },
                          }}
                        />
                      </AvGroup>

                      <AvGroup>
                        <Label id="rootComponentId" for="rootComponentId" className="create-product-page-label">
                          Root Component ID
                        </Label>
                        <AvField
                          id="rootComponentId"
                          type="text"
                          name="rootComponentId"
                          validate={{
                            required: { value: true, errorMessage: 'This field is required.' },
                          }}
                        />
                      </AvGroup>
                      <AvGroup>
                        <Label id="roomleComponentIdLabel" for="product-roomleComponentId" className="create-product-page-label">
                          Roomle Component Id
                        </Label>
                        <Row>
                          <Col md="7">
                            <AvField
                              id="product-roomleComponentId"
                              type="text"
                              name="roomleComponentId"
                              onChange={onRoomleComponentIdChange}
                            />
                          </Col>
                          <Col md="5">
                            <Button
                              color="primary"
                              onClick={toggle}
                              style={{ fontSize: 12, paddingHorizontal: 10, paddingVertical: 5 }}
                              disabled={!isButtonEnabled}
                            >
                              Show Roomle Configuration
                            </Button>
                          </Col>
                        </Row>
                      </AvGroup>
                      <Collapse isOpen={isOpen}>
                        <Label id="parameterValues" className="create-product-page-label">
                          Parameter Mapping
                        </Label>
                        <div>
                          {roomleConfiguration && (
                            <dd style={{ background: 'lightgrey', borderRadius: '10px' }}>
                              <pre>{JSON.stringify(getParameterMapping(roomleConfiguration), null, 2)}</pre>
                            </dd>
                          )}
                        </div>
                        <AvGroup>
                          <Label id="configuration" for="product-configuration" className="create-product-page-label">
                            Configuration Hash
                          </Label>
                          <AvField
                            id="product-configuration"
                            type="text"
                            name="configuration"
                            disabled
                            value={roomleConfiguration && getHashConfiguration(roomleConfiguration)}
                          />
                        </AvGroup>
                      </Collapse>
                      <AvGroup>
                        <Label id="descriptionLabel" for="product-description" className="create-product-page-label">
                          Description
                        </Label>
                        <AvField id="product-description" type="text" name="description" />
                      </AvGroup>
                      <AvGroup>
                        <Label for="product-manufacturer" className="create-product-page-label">
                          Manufacturer
                        </Label>
                        <AvInput id="product-manufacturer" type="select" className="form-control" name="manufacturerId" required>
                          {manufacturers
                            ? manufacturers.map(manufacturer => (
                                <option value={manufacturer.id} key={manufacturer.id}>
                                  {manufacturer.name}
                                </option>
                              ))
                            : null}
                        </AvInput>
                        <AvFeedback>This field is required.</AvFeedback>
                      </AvGroup>
                      <AvGroup>
                        <Label id="baseURL" for="baseURL" className="create-product-page-label">
                          Base URL
                        </Label>
                        <AvField id="baseURL" type="text" name="baseURL" />
                      </AvGroup>
                      <AvGroup>
                        <Label id="revitProjectFile" for="revitProjectFile" className="create-product-page-label">
                          Revit Project File
                        </Label>
                        <AvField id="revitProjectFile" type="text" name="revitProjectFile" />
                      </AvGroup>

                      <AvGroup>
                        <Label for="roomleOrigin" className="create-product-page-label">
                          Roomle Origin
                        </Label>
                        <AvInput id="roomleOrigin" type="select" className="form-control" name="roomleOrigin" required>
                          <option value={''}>Select item</option>
                          <option value={'LEFT'}>Left</option>
                          <option value={'CENTER'}>Center</option>
                        </AvInput>
                        <AvFeedback>This field is required.</AvFeedback>
                      </AvGroup>

                      <div className="create-product-page-checkboxes-container">
                        <AvGroup check className="create-product-page-checkbox-group ">
                          <Label
                            id="exportWrittenSpecs"
                            for="product-exportWrittenSpecs"
                            className="create-product-page-label create-product-page-checkbox-label"
                          >
                            <AvInput
                              id="product-exportWrittenSpecs"
                              type="checkbox"
                              className="checkbox-form-control"
                              name="exportWrittenSpecs"
                            />
                            Export Written Specs
                          </Label>
                        </AvGroup>


                        <AvGroup check className="create-product-page-checkbox-group ">
                          <Label
                            id="cleanRevitFamily"
                            for="product-cleanRevitFamily"
                            className="create-product-page-label create-product-page-checkbox-label"
                          >
                            <AvInput
                              id="product-cleanRevitFamily"
                              type="checkbox"
                              className="checkbox-form-control"
                              name="cleanRevitFamily"
                            />
                            Clean Revit Family
                          </Label>
                        </AvGroup>

                        <AvGroup check className="create-product-page-checkbox-group ">
                          <Label
                            id="showRevitSetMountingHeight"
                            for="product-showRevitSetMountingHeight"
                            className="create-product-page-label create-product-page-checkbox-label"
                          >
                            <AvInput
                              id="product-showRevitSetMountingHeight"
                              type="checkbox"
                              className="checkbox-form-control"
                              name="showRevitSetMountingHeight"
                            />
                            Show Revit Set Mounting Height
                          </Label>
                        </AvGroup>

                        <AvGroup check className="create-product-page-checkbox-group">
                          <Label
                            id="complexFamilyLabel"
                            for="product-complexFamily"
                            className="create-product-page-label create-product-page-checkbox-label"
                          >
                            <AvInput id="product-complexFamily" type="checkbox" className="checkbox-form-control" name="complexFamily" />
                            Is Complex Family
                          </Label>
                        </AvGroup>
                        <AvGroup check className="create-product-page-checkbox-group">
                          <Label id="isMOC" for="product-isMOC" className="create-product-page-label create-product-page-checkbox-label">
                            <AvInput id="product-isMOC" type="checkbox" className="checkbox-form-control" name="isMOC" />
                            Is this a Multi-Object Configurator?
                          </Label>
                        </AvGroup>
                        <AvGroup check className="create-product-page-checkbox-group">
                          <Label
                            id="visibleToAdmin"
                            for="product-visibleToAdmin"
                            className="create-product-page-label create-product-page-checkbox-label"
                          >
                            <AvInput id="product-visibleToAdmin" type="checkbox" className="checkbox-form-control" name="visibleToAdmin" />
                            Only Visible for Admin Users
                          </Label>
                        </AvGroup>

                        <AvGroup check className="create-product-page-checkbox-group">
                          <Label id="showInMarketPlaceLabel" className="create-product-page-label create-product-page-checkbox-label">
                            <AvInput
                              id="product-showInMarketPlace"
                              type="checkbox"
                              className="checkbox-form-control"
                              name="showInMarketPlace"
                            />
                            Show In Market Place
                          </Label>
                        </AvGroup>

                        <AvGroup check className="create-product-page-checkbox-group">
                          <Label id="revitExportEnable" className="create-product-page-label create-product-page-checkbox-label">
                            <AvInput
                              id="product-revitExportEnable"
                              type="checkbox"
                              className="checkbox-form-control"
                              name="revitExportEnable"
                            />
                            Revit Export Enable
                          </Label>
                        </AvGroup>
                        <AvGroup check className="create-product-page-checkbox-group">
                          <Label id="hideSelectedOptions" className="create-product-page-label create-product-page-checkbox-label">
                            <AvInput
                              id="product-hideSelectedOptions"
                              type="checkbox"
                              className="checkbox-form-control"
                              name="hideSelectedOptions"
                            />
                            Hide Selected Options ?
                          </Label>
                        </AvGroup>
                        <AvGroup check className="create-product-page-checkbox-group">
                          <Label id="hideParts" className="create-product-page-label create-product-page-checkbox-label">
                            <AvInput id="product-hideParts" type="checkbox" className="checkbox-form-control" name="hideParts" />
                            Hide Parts ?
                          </Label>
                        </AvGroup>

                        <AvGroup check className="create-product-page-checkbox-group">
                          <Label id="lineUpFamily" className="create-product-page-label create-product-page-checkbox-label">
                            <AvInput id="product-lineUpFamily" type="checkbox" className="checkbox-form-control" name="lineUpFamily" />
                            Is Line Up Family ?
                          </Label>
                        </AvGroup>

                        <AvGroup check className="create-product-page-checkbox-group">
                          <Label id="active" className="create-product-page-label create-product-page-checkbox-label">
                            <AvInput id="product-active" type="checkbox" className="checkbox-form-control" name="active" />
                            Active ?
                          </Label>
                        </AvGroup>

                        <AvGroup check className="create-product-page-checkbox-group">
                          <Label id="selectComponentAfterDocking" className="create-product-page-label create-product-page-checkbox-label">
                            <AvInput
                              id="product-selectComponentAfterDocking"
                              type="checkbox"
                              className="checkbox-form-control"
                              name="selectComponentAfterDocking"
                            />
                            Select Component After Docking ?
                          </Label>
                        </AvGroup>
                      </div>
                    </div>
                    {configurationId !== null && configurationId !== '' ? (
                      <div id="configurator-container" style={{ height: '50vh' }}></div>
                    ) : null}
                  </div>
                  {!isNew && (
                    <>
                      <h5 className={'mt-5 mb-4 create-product-page-title'}>Specialty Equipment</h5>
                      <div className={'ml-4 create-product-page-uploads-container'}>
                        <AvGroup className="create-product-page-upload-group">
                          <Label id="familyFilePathLabel-2019" for="product-familyFilePath-2019" className="create-product-page-label">
                            Family File Path 2019
                          </Label>
                          <br />
                          <Row>
                            <UploadFile equipmentType={SPECIALITY_EQUIPMENT} fieldName={'2019'} productId={productEntity.id} />
                            <UploadComponents equipmentType={SPECIALITY_EQUIPMENT} fieldName={'2019'} productId={productEntity.id} />
                          </Row>
                        </AvGroup>
                        <AvGroup className="create-product-page-upload-group">
                          <Label id="familyFilePathLabel-2020" for="product-familyFilePath-2020" className="create-product-page-label">
                            Family File Path 2020
                          </Label>
                          <br />
                          <Row>
                            <UploadFile equipmentType={SPECIALITY_EQUIPMENT} fieldName={'2020'} productId={productEntity.id} />
                            <UploadComponents equipmentType={SPECIALITY_EQUIPMENT} fieldName={'2020'} productId={productEntity.id} />
                          </Row>
                        </AvGroup>
                        <AvGroup className="create-product-page-upload-group">
                          <Label id="familyFilePathLabel-2021" for="product-familyFilePath-2021" className="create-product-page-label">
                            Family File Path 2021
                          </Label>
                          <br />
                          <Row>
                            <UploadFile equipmentType={SPECIALITY_EQUIPMENT} fieldName={'2021'} productId={productEntity.id} />
                            <UploadComponents equipmentType={SPECIALITY_EQUIPMENT} fieldName={'2021'} productId={productEntity.id} />
                          </Row>
                        </AvGroup>
                        <AvGroup className="create-product-page-upload-group">
                          <Label id="familyFilePathLabel-2022" for="product-familyFilePath-2022" className="create-product-page-label">
                            Family File Path 2022
                          </Label>
                          <br />
                          <Row>
                            <UploadFile equipmentType={SPECIALITY_EQUIPMENT} fieldName={'2022'} productId={productEntity.id} />
                            <UploadComponents equipmentType={SPECIALITY_EQUIPMENT} fieldName={'2022'} productId={productEntity.id} />
                          </Row>
                        </AvGroup>
                        <AvGroup className="create-product-page-upload-group">
                          <Label id="familyFilePathLabel-2022" for="product-familyFilePath-2023" className="create-product-page-label">
                            Family File Path 2023
                          </Label>
                          <br />
                          <Row>
                            <UploadFile equipmentType={SPECIALITY_EQUIPMENT} fieldName={'2023'} productId={productEntity.id} />
                            <UploadComponents equipmentType={SPECIALITY_EQUIPMENT} fieldName={'2023'} productId={productEntity.id} />
                          </Row>
                        </AvGroup>
                        <AvGroup className="create-product-page-upload-group">
                          <Label id="familyFilePathLabel-2024" for="product-familyFilePath-2024" className="create-product-page-label">
                            Family File Path 2024
                          </Label>
                          <br />
                          <Row>
                            <UploadFile equipmentType={SPECIALITY_EQUIPMENT} fieldName={'2024'} productId={productEntity.id} />
                            <UploadComponents equipmentType={SPECIALITY_EQUIPMENT} fieldName={'2024'} productId={productEntity.id} />
                          </Row>
                        </AvGroup>
                      </div>
                      <h5 className={'mt-5 mb-4 create-product-page-title'}>Food Service Equipment</h5>
                      <div className={'ml-4 create-product-page-uploads-container'}>
                        <>
                          <AvGroup className="create-product-page-upload-group">
                            <Label id="familyFilePathLabel-2022" for="product-familyFilePath-2022" className="create-product-page-label">
                              Family File Path 2022
                            </Label>
                            <br />
                            <Row>
                              <UploadFile equipmentType={FOOD_SERVICE_EQUIPMENT} fieldName={'2022'} productId={productEntity.id} />
                              <UploadComponents equipmentType={FOOD_SERVICE_EQUIPMENT} fieldName={'2022'} productId={productEntity.id} />
                            </Row>
                          </AvGroup>
                          <AvGroup className="create-product-page-upload-group">
                            <Label id="familyFilePathLabel-2023" for="product-familyFilePath-2023" className="create-product-page-label">
                              Family File Path 2023
                            </Label>
                            <br />
                            <Row>
                              <UploadFile equipmentType={FOOD_SERVICE_EQUIPMENT} fieldName={'2023'} productId={productEntity.id} />
                              <UploadComponents equipmentType={FOOD_SERVICE_EQUIPMENT} fieldName={'2023'} productId={productEntity.id} />
                            </Row>
                          </AvGroup>
                          <AvGroup className="create-product-page-upload-group">
                            <Label id="familyFilePathLabel-2024" for="product-familyFilePath-2024" className="create-product-page-label">
                              Family File Path 2024
                            </Label>
                            <br />
                            <Row>
                              <UploadFile equipmentType={FOOD_SERVICE_EQUIPMENT} fieldName={'2024'} productId={productEntity.id} />
                              <UploadComponents equipmentType={FOOD_SERVICE_EQUIPMENT} fieldName={'2024'} productId={productEntity.id} />
                            </Row>
                          </AvGroup>
                        </>
                      </div>
                    </>
                  )}
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button tag={Link} id="cancel-save" to="/product" replace color="info" style={{ height: 40, marginTop: 50 }}>
                      <FontAwesomeIcon icon="arrow-left" />
                      &nbsp;
                      <span className="d-none d-md-inline">Back</span>
                    </Button>
                    &nbsp;
                    <Button
                      color="primary"
                      id="save-entity"
                      type="submit"
                      disabled={updating || !isButtonEnabled}
                      style={{ height: 40, marginTop: 50 }}
                    >
                      <FontAwesomeIcon icon="save" />
                      &nbsp; Save
                    </Button>
                    {isNew && (
                      <AvGroup hidden>
                        <Label for="product-filename" className="create-product-page-label">
                          File Name
                        </Label>
                        <AvInput
                          id="product-filename"
                          type="text"
                          className="form-control"
                          name="familyFilename"
                          value={filename}
                        ></AvInput>
                        {/* <AvFeedback>This field is required.</AvFeedback> */}
                      </AvGroup>
                    )}
                  </div>
                </AvForm>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  account: storeState.authentication.account,
  manufacturers: storeState.manufacturer.entities,
  productEntity: storeState.product.entity,
  loading: storeState.product.loading,
  updating: storeState.product.updating,
  updateSuccess: storeState.product.updateSuccess,
  errorMessage: storeState.product.errorMessage,
});

const mapDispatchToProps = {
  getEnabledManufacturers,
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProductUpdate);
