import { Moment } from 'moment';

export const enum ConfigurationType {
  PROJECT = 'PROJECT',
  SINGLE = 'SINGLE',
}

export const enum RoomleOrigin {
  LEFT = 'LEFT',
  CENTER = 'CENTER',
}

export interface IUserConfiguration {
  id?: number;
  visible?: boolean;
  modelNumber?: string;
  description?: string;
  thumbnailImage?: string;
  configSize?: string;
  cutsheetUrl?: string;
  typename?: string;
  typecatalog?: string;
  configuration?: string;
  typecatalogheader?: string;
  baseFamily?: string;
  configSizeInch?: string;
  dateCreated?: Moment;
  type?: ConfigurationType;
  positionX?: string;
  positionY?: string;
  positionZ?: string;
  rotation?: string;
  roomleComponentId?: string;
  parts?: any;
  revitExportEnable?: boolean;
  shareBy?: string;
  seen?: boolean;
  showRevitSetMountingHeight?: boolean;
  cleanRevitFamily?: boolean;
  sectionPrefix?: string;
  isLineUpFamily?: boolean;
  baseURL?: string;
  roomleOrigin?: RoomleOrigin;
  dimensionX?: string;
  dimensionY?: string;
  dimensionZ?: string;
  specsMfr?: string;
  specsQuantity?: string;
  specsModel?: string;
  specsDescription?: string;
  specsHeaders?: string;
  userLogin?: string;
  userId?: number;
  userHash?: string;
  productTypename?: string;
  productName?: string;
  productId?: number;
  folderName?: string;
  folderId?: number;
  composite?: object;
  complexFamily?: boolean;
  manufacturer?: string;
  manufacturerName?: string;
  manufacturerLogo?: string;
  projectId?: number;
  parameterValues?: any;
  customParameter1?: string;
  manufacturerRefData?: any;
  rootComponentCenterX?: any;
  rootComponentCenterY?: any;
  rootComponentCenterZ?: any;
}

export const defaultValue: Readonly<IUserConfiguration> = {
  visible: false,
  revitExportEnable: false,
  seen: false,
  showRevitSetMountingHeight: false,
  cleanRevitFamily: false,
  isLineUpFamily: false,
};
