import { IRootState } from 'app/shared/reducers';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import React, { useEffect, useState, useMemo, useCallback, lazy, Suspense } from 'react';
import { getSortState, JhiPagination } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Row } from 'reactstrap';
import { getEntities } from './roomle-product.reducer';
import { getEntity } from '../product/product.reducer';
import { getManufacturerForCurrentUser } from '../manufacturer/manufacturer.reducer';
import MD5 from 'crypto-js/md5';
import { groupBy } from 'lodash';
import ProductCard from './ProductCard';
import './products.scss';
import Search from 'app/components/Search/Search';
const ProductDetailsModal = lazy(() => import('./ProductDetailsModal'));
import AppSpinner from 'app/components/Spinner/Spinner';
import NoDataBanner from 'app/components/NoDataBanner/NoDataBanner';

export interface IRoomleProductProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string; mailsha1: string }> {}

export const RoomleProduct = (props: IRoomleProductProps) => {
  const { productList, match, loading, totalItems } = props;
  const mailsha1 = useMemo(() => {
    return props.match.params.mailsha1 ? props.match.params.mailsha1 : MD5(props.account.email).toString();
  }, [props.match.params.mailsha1, props.account.email]);
  const [isProductDetailsModalOpen, setisProductDetailsModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const cantColumns = 4;
  const ITEMS_PER_PAGE = 48;

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE), props.location.search)
  );

  const groupedByManufacturersComnfigurators = useMemo(() => groupByManufacturerName(productList), [productList]);

  const getAllEntities = () => {
    props.getEntities(
      paginationState.activePage - 1,
      paginationState.itemsPerPage,
      `${paginationState.sort},${paginationState.order}`,
      //@ts-ignore
      searchValue
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    if (!searchValue) {
      sortEntities();
      return;
    }

    const delayDebounceFn = setTimeout(() => {
      sortEntities();
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [paginationState.activePage, paginationState.order, paginationState.sort, searchValue]);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const page = params.get('page');
    const sort = params.get('sort');
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [props.location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === 'asc' ? 'desc' : 'asc',
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  function groupByManufacturerName(elements) {
    const groupedArr = groupBy(elements, elements => elements.manufacturerName);
    return groupedArr;
  }

  const redirectToDetails = useCallback(
    (product, e) => {
      if (e.target.nodeName === 'I') {
        return;
      } else {
        if (props.match.params.mailsha1) {
          props.history.push(`${match.url}/${product.id}/${product.roomleComponentId}`);
        } else {
          props.history.push(`${match.url}/${mailsha1}/${product.id}/${product.roomleComponentId}`);
        }
      }
    },
    [props.match.params.mailsha1, props.history, props.match.url, mailsha1]
  );

  const openProductDetailsModal = product => {
    setisProductDetailsModalOpen(true);
    props.getEntity(product.id);
    props.getManufacturerForCurrentUser();
  };

  const closeModal = useCallback(() => {
    setisProductDetailsModalOpen(false);
  }, []);

  return (
    <div>
      <div className="search-configurators-container">
        <Search searchValue={searchValue} setSearchValue={setSearchValue} />
      </div>
      {props.loading ? (
        <AppSpinner />
      ) : (
        <div style={{ paddingTop: 32, paddingLeft: 32, paddingBottom: 32 }}>
          {productList && productList.length > 0
            ? Object.keys(groupedByManufacturersComnfigurators).map(group => (
                <div className="product-group-by-man-mane" key={group}>
                  <span className="product-group-title">{group}</span>
                  <div className="product-group-items-container">
                    {groupedByManufacturersComnfigurators[group].map(product => (
                      <div className="configurators-product-card" key={product.id} onClick={e => redirectToDetails(product, e)}>
                        <i className="product-details-button" onClick={e => openProductDetailsModal(product)}>
                          i
                        </i>
                        <ProductCard image={product.image} manufacturer={product.manufacturerName} name={product.name} />
                      </div>
                    ))}
                  </div>
                </div>
              ))
            : !loading && (
                <div style={{ marginTop: 80 }}>
                  <NoDataBanner title={'There are no Configurators!'} />
                </div>
              )}
          {props.totalItems > 24 ? (
            <div className={productList && productList.length > paginationState.itemsPerPage ? 'pagination-container' : 'd-none'}>
              <Row className="justify-content-center">
                <JhiPagination
                  activePage={paginationState.activePage}
                  onSelect={handlePagination}
                  maxButtons={5}
                  itemsPerPage={paginationState.itemsPerPage}
                  totalItems={props.totalItems}
                />
              </Row>
            </div>
          ) : (
            ''
          )}
          <Suspense fallback={<div>Loading...</div>}>
            <ProductDetailsModal
              isOpen={isProductDetailsModalOpen}
              product={props.productDetails}
              closeModal={closeModal}
              redirectToDetails={redirectToDetails}
              userManufacturer={props.userManufacturer}
              loading={props.productLoading}
            />
          </Suspense>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ roomleProduct, authentication, product, manufacturer }: IRootState) => ({
  productList: roomleProduct.entities,
  loading: roomleProduct.loading,
  totalItems: roomleProduct.totalItems,
  account: authentication.account,
  productDetails: product.entity,
  productLoading: product.loading,
  userManufacturer: manufacturer.entity,
});

const mapDispatchToProps = {
  getEntities,
  getEntity,
  getManufacturerForCurrentUser,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(RoomleProduct);
