import RoomleConfiguratorApi from '@roomle/embedding-lib/roomle-configurator-api.es.min.js';
import React, { forwardRef, useEffect, useState, useImperativeHandle } from 'react';
import { getSortedPartListForFaucetFlex } from './KrowneFaucetFlexUtils';
import { getSortedPartListForDraftBeer, getInstallHours } from './KrowneDraftBeerUtils';
import { getSortedPartListForThermoKoolWalkIns, getParameter, getCSVContent } from './ThermoKoolWalkInsUtils';
import { getSortedPartListForBarFlex } from './KrowneBarFlexUtils';

export interface IRoomleProps {
  configurationId: string;
  clientId: string;
  saveConfiguration: any;
  saveButton: boolean;
  handleFinishLoading: any;
  unitsOfMeasure?: string;
  setRoomleConfiguration?: Function;
  isRequestQuotesButtonWasPressed?: boolean;
  setIsRequestQuotesButtonWasPressed?: Function;
  isShowInMarketPlace?: boolean;
  isInProduction: boolean;
  manufacturerName: string;
  roomleLoaded: boolean;
  setRoomleLoaded: Function;
  roomleComponentId: string;
  isSideBarCollapsed: boolean;
  isFullScreenMode: boolean;
  currentProject: any;
  selectComponentAfterDocking: boolean;
}

export const Roomle = forwardRef((props: IRoomleProps, ref) => {
  const [roomle, setRoomle] = useState(null);
  const [exportInProgress, setExportInProgress] = useState(false);
  const [configurator, setConfigurator] = useState(null);

  useImperativeHandle(ref, () => ({
    requestProductOutsideTheIframe() {
      configurator.ui.triggerRequestProduct();
    },
  }));

  useEffect(() => {
    (async () => {
      const options = {
        locale: 'en',
        unit: props.unitsOfMeasure,
        deeplink: '#CONFIGURATIONID#',
        translations: {
          en: {
            params: {
              'request-product': 'Request Quote',
            },
          },
        },
      };
      const configurator = await RoomleConfiguratorApi.create(props.clientId, document.getElementById('configurator-container'), {
        ...options,
        buttons: {
          savedraft: false,
          startconfigure: props.saveButton,

          requestproduct: false,
          // requestproduct:
          //   props.roomleComponentId === 'krowne:draft' ||
          //   props.roomleComponentId === 'thermo-kool:walk-ins_pid' ||
          //   props.roomleComponentId === 'krowne:faucet_pid' ||
          //   props.roomleComponentId === 'krowne:barflex_configurator'
          //     ? true
          //     : false,

          export3d: false,
        },
      });
      setRoomle(configurator);

      configurator.ui.callbacks.onButtonClicked = name => {
        if (name === 'requestproduct') {
          props.setIsRequestQuotesButtonWasPressed(true);
        }
        if (name === 'addons') {
          const elementMovedParam = { key: 'elementMoved' };
          configurator.extended.setParameterOfRootComponent(elementMovedParam, '0');
        }
      };

      configurator.extended.callbacks.onElementDocked = data => {
        if (props.selectComponentAfterDocking) {
          configurator.extended.selectComponent(data.id);
          if (props.roomleComponentId === 'krowne:barflex_configurator') {
            configurator.ui.setActiveParameterGroup('grpElementType');
          } else if (props.roomleComponentId === 'thermo-kool:walk-ins_pid') {
            configurator.ui.setActiveParameterGroup('grpSetup');
          } else {
            configurator.ui.setActiveParameterGroup('grpCounterType');
          }
        }
      };

      await configurator.ui.loadObject(props.configurationId);

      configurator.ui.callbacks.onRequestProduct = (configurationId, image, partlist, price, labels, configuration) => {
        setExportInProgress(true);
        props.setRoomleConfiguration(configuration);

        if (props.roomleComponentId === 'thermo-kool:walk-ins_pid' && props.isRequestQuotesButtonWasPressed) {
          const parts = getSortedPartListForThermoKoolWalkIns(partlist).slice(0, 1);
          const csvContent = getCSVContent(configuration.configuration);

          const mailConfigParam = {
            configLink: options.deeplink.replace('#CONFIGURATIONID#', configurationId),
            configImage: configuration.perspectiveImage,
            sendTwoEmails: true,
            userEmailData: {
              from: {
                email: 'sales@thermokool.com',
                name: 'Thermo-Kool Sales',
              },
              subject: 'Thermo-Kool Walk-In Configurator',
              attachment: {
                include: false,
                name: 'Thermo-Kool Walk-Ins.pdf',
              },
              templateId: 'd-65d4e9b7754d41eeab6de2a90c54d773',
            },
            mfrEmailData: {
              from: {
                email: 'sales@thermokool.com',
                name: 'Thermo-Kool Sales',
              },
              subject: 'Thermo-Kool Walk-In Configurator - Manufacturer',
              templateId: 'd-b3f859e4cb624ffe980a8166b6e0348a',
              manufacturer: getParameter(configuration.configuration, 'manufacturer'),
              sendCSV: true,
              csvContent: csvContent,
            },
          };

          const RoomleConfiguratorParam = {
            configurationId,
            fullList: parts,
            price,
            labels,
            mailConfigParam,
          };

          props.setRoomleConfiguration(RoomleConfiguratorParam);
        } else if (props.roomleComponentId === 'krowne:draft' && props.isRequestQuotesButtonWasPressed) {
          const installHours = getInstallHours(configuration).installHours;
          const parts = getSortedPartListForDraftBeer(partlist);

          const mailConfigParam = {
            installHours: installHours,
            configLink: options.deeplink.replace('#CONFIGURATIONID#', configurationId),
            configImage: configuration.perspectiveImage,
            sendTwoEmails: true,
            userEmailData: {
              from: {
                email: 'beerflex@krowne.com',
                name: 'BeerFlex',
              },
              subject: 'Krowne BeerFlex',
              attachment: {
                include: true,
                name: 'Beer System Pamphlet.pdf',
              },
              templateId: 'd-b6aa841540ae446f8dad36b1c16ad77e',
            },
            mfrEmailData: {
              from: {
                email: 'beerflex@krowne.com',
                name: 'BeerFlex',
              },
              to: {
                email: 'beerflex@kitchautomation.com',
                name: 'BeerFlex',
              },
              subject: 'Krowne BeerFlex',
              templateId: 'd-7f723cdc7ece446185f27a2cd15de061',
            },
          };

          const RoomleConfiguratorParam = {
            configurationId,
            image,
            fullList: parts,
            price,
            labels,
            mailConfigParam,
          };
          props.setRoomleConfiguration(RoomleConfiguratorParam);
        } else if (props.roomleComponentId === 'krowne:faucet_pid' && props.isRequestQuotesButtonWasPressed) {
          const parts = getSortedPartListForFaucetFlex(partlist);

          const mailConfigParam = {
            configLink: options.deeplink.replace('#CONFIGURATIONID#', configurationId),
            configImage: configuration.perspectiveImage,
            sendTwoEmails: false,
            userEmailData: {
              from: {
                email: 'plumbing@krowne.com',
                name: 'FaucetFlex',
              },
              bcc: [
                {
                  email: 'plumbing@krowne.com',
                  name: 'FaucetFlex',
                },
              ],
              subject: 'Krowne FaucetFlex',
              attachment: {
                include: false,
                name: 'Krowne Refrigeration Manual.pdf',
              },
              templateId: 'd-860726710a284b8ebaabb5de072acc49',
            },
            mfrEmailData: {
              from: {
                email: 'plumbing@krowne.com',
                name: 'FaucetFlex',
              },
              to: {
                email: 'plumbing@krowne.com',
                name: 'FaucetFlex',
              },
              subject: 'Krowne FaucetFlex',
              templateId: 'd-860726710a284b8ebaabb5de072acc49',
            },
          };

          const RoomleConfiguratorParam = {
            configurationId,
            fullList: parts,
            price,
            labels,
            mailConfigParam,
          };

          props.setRoomleConfiguration(RoomleConfiguratorParam);
        } else if (props.roomleComponentId === 'krowne:barflex_configurator' && props.isRequestQuotesButtonWasPressed) {
          const installHours = getInstallHours(configuration).installHours;
          const parts = getSortedPartListForBarFlex(partlist);

          const mailConfigParam = {
            installHours: installHours,
            configLink: options.deeplink.replace('#CONFIGURATIONID#', configurationId),
            configImage: configuration.perspectiveImage,
            sendTwoEmails: true,
            userEmailData: {
              from: {
                email: 'beerflex@krowne.com',
                name: 'BeerFlex',
              },
              subject: 'Krowne BeerFlex',
              attachment: {
                include: true,
                name: 'Beer System Pamphlet.pdf',
              },
              templateId: 'd-b6aa841540ae446f8dad36b1c16ad77e',
            },
            mfrEmailData: {
              from: {
                email: 'beerflex@krowne.com',
                name: 'BeerFlex',
              },
              to: {
                email: 'beerflex@krowne.com',
                name: 'BeerFlex',
              },
              subject: 'Krowne BeerFlex',
              templateId: 'd-7f723cdc7ece446185f27a2cd15de061',
            },
          };

          const RoomleConfiguratorParam = {
            configurationId,
            image,
            fullList: parts,
            price,
            labels,
            mailConfigParam,
          };

          props.setRoomleConfiguration(RoomleConfiguratorParam);
        }
      };

      if (props.handleFinishLoading !== null) props.handleFinishLoading(configurator);
      setConfigurator(configurator);
      props.setRoomleLoaded(true);
    })();
  }, []);

  let roomleContainerClass;
  if (!props.isSideBarCollapsed && props.currentProject.id) {
    roomleContainerClass = 'roomle-configurator-container-fullscreen-project-mode';
  }
  if (props.isSideBarCollapsed && props.currentProject.id) {
    roomleContainerClass = 'roomle-configurator-container-fullscreen';
  }

  if (props.isSideBarCollapsed && !props.currentProject.id) {
    roomleContainerClass = 'roomle-configurator-container-fullscreen-default';
  }

  if (!props.isSideBarCollapsed && !props.currentProject.id) {
    roomleContainerClass = 'roomle-configurator-container-default';
  }

  return <div id="configurator-container" className={roomleContainerClass}></div>;
});

export default Roomle;
