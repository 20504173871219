import React, {useState} from 'react';
import axios from 'axios';

function UploadFile({setOutput}) {
  const [progress, setProgress] = useState(0);


  function updatePercentage(progressEvent) {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
    setProgress(percentCompleted)
  }

  const onBlobChange = event => {
    const file = event.target.files[0];
    const fileName = event.target.files[0].name;
    axios.post(`api/manufacturers/generateFileUploadSignedLink`, null, {// fetching signed url for upload file
      params: {
        fileName,
        uploadFile: true
      }
    }).then(link => {
      const options = {
        headers: {'Content-Type': "application/octet-stream"},
        onUploadProgress: updatePercentage
      };
      const instance = axios.create();
      delete instance.defaults.headers.common['Authorization'];
      instance.put(link.data, file, options).then(() => {// using signed url uploadin the file
        axios.post(`api/manufacturers/generateFileUploadSignedLink`, null, {// on success file upload fetching file url to save on database
          params: {
            fileName,
            uploadFile: false
          }
        }).then(response => {
          const url = response.data
          setOutput(url);
        })
      })
    })
  };
  return (
    <div className="form-group file-area">
      <input id="year2022" type="file" accept={"image/*"} onChange={onBlobChange}/>
      <div className="file-dummy">
        {progress > 0 ? (<div className="text-center">{progress}%</div>) : ""}
        <div
          className="default">{progress > 0 && progress < 100 ? "Uploading . . ." : progress === 100 ? "Completed" : "Please select file."}</div>
      </div>
    </div>
  );
}

export default UploadFile;
