import React, { useEffect, useState } from 'react';
import { Modal, Input, Form } from 'reactstrap';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import CancelButton from '../CancelButton/CancelButton';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { setNewProjectName, createProject, getAllProjectByLoginUser } from 'app/entities/project/project.reducer';
import './createProjectNameFolder.scss';

interface ICreateProjectNameModal extends StateProps, DispatchProps {
  isOpen: boolean;
  closeModal: Function;
  closeAddToProjectModal?: Function;
  isCreateWithFloorPlan: boolean;
}

const CreateProjectNameModal = (props: ICreateProjectNameModal) => {
  let history = useHistory();
  let location = useLocation();
  const [isNewProjectIsCreating, setIsNewProjectIsCreating] = useState<boolean>(false);

  useEffect(() => {
    props.setNewProjectName('');
  }, []);

  useEffect(() => {
    if (props.currentProject && isNewProjectIsCreating) {
      history.push(`/edit-project/${props.currentProject.id}`);
    }
  }, [props.currentProject]);

  const createWithFloorPlan = e => {
    e.preventDefault();
    setIsNewProjectIsCreating(true);
    props.createProject({ projectName: props.newProjectName, isRoomDesign: true });
  };

  const createWithConfigurations = e => {
    e.preventDefault();
    props.createProject({ projectName: props.newProjectName, isRoomDesign: false });

    if (location.pathname.includes('configurators')) {
      props.closeModal();
      props.closeAddToProjectModal();
    } else {
      history.push(`/`);
    }
  };

  return (
    <Modal
      isOpen={props.isOpen}
      centered
      fade={false}
      style={{ maxWidth: 700, width: window.screen.width - 1000 }}
      toggle={() => props.closeModal()}
      backdrop={true}
      autoFocus={false}
    >
      <div className="create-project-name-modal-container">
        <span className="create-project-name-modal-title">Project Name</span>
        <span className="create-folder-modal-input-label">New Project Name</span>
        <Form onSubmit={props.isCreateWithFloorPlan ? createWithFloorPlan : createWithConfigurations}>
          <Input onChange={e => props.setNewProjectName(e.target.value)} autoFocus={true} className="create-project-name-modal-input" />
          <div className="create-project-name-modal-actions-container">
            <ConfirmButton
              title="Save"
              // confirmAction={props.isCreateWithFloorPlan ? createWithFloorPlan : createWithConfigurations}
              disabled={!props.newProjectName}
            />
            <CancelButton title="Cancel" confirmAction={props.closeModal} />
          </div>
        </Form>
      </div>
    </Modal>
  );
};

const mapStateToProps = ({ project }: IRootState) => ({
  newProjectName: project.newProjectName,
  currentProject: project.entity,
});

const mapDispatchToProps = {
  setNewProjectName,
  createProject,
  getAllProjectByLoginUser,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CreateProjectNameModal);
